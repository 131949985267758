<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据中心</el-breadcrumb-item>
      <el-breadcrumb-item>退款列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索 添加 -->
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            clearable
            @clear="getList">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </el-col>
        <!-- <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true">添加会员</el-button>
        </el-col> -->
      </el-row>
      <!-- 用户列表区域 -->
      <el-table :data="refundList" border stripe>
        <el-table-column
          width="270px"
          prop="order_no"
          label="订单编号"
        ></el-table-column>
        <el-table-column
          width="270px"
          prop="out_trade_no"
          label="交易订单号"
        ></el-table-column>
        <el-table-column
          width="270px"
          prop="out_trade_no"
          label="退款编号"
        ></el-table-column>
        <el-table-column width="150px" label="订单金额（元）">
          <template slot-scope="scope">
            {{ scope.row.total_fee / 100 }}
          </template>
        </el-table-column>
        <el-table-column width="150px" label="退款金额（元）">
          <template slot-scope="scope">
            {{ scope.row.refund_fee / 100 }}
          </template>
        </el-table-column>
        <el-table-column width="150px" label="状态">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.status == 0">{{ getStatus(0) }}</el-tag>
            <el-tag type="success" v-else> {{ getStatus(scope.row.refund_status)  }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="180px" label="创建时间">
          <template slot-scope="scope">
            {{
              scope.row.create_time ? scope.row.create_time : "" | dataFormat
            }}
          </template>
        </el-table-column>
        <el-table-column width="180px" label="退款时间">
          <template slot-scope="scope">
            {{
              scope.row.update_time ? scope.row.update_time : "" | dataFormat
            }}
          </template>
        </el-table-column>
        <el-table-column prop="refund_desc" label="退款原因"></el-table-column>
        <!-- <el-table-column label="操作" fixed="right" width="150px">
          <template slot-scope="scope">
            <el-button
              v-if="
                scope.row.status == 1 &&
                scope.row.refund_fee < scope.row.total_fee
              "
              type="primary"
              icon="el-icon-revert"
              size="mini"
              title="退款"
              @click="refund(scope.row, scope.$index)"
              >退款</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[2, 5, 10, 15, 20]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import { refundStatus } from "@/config/index.js";
import { enumConverter, filterToWhere } from "@/common/validator.js";
import _ from "lodash";
export default {
  data() {
    return {
      // 获取用户列表查询参数对象
      queryInfo: {
        query: "",
        // 当前页数
        page: 1,
        // 每页显示多少数据
        limit: 10,
      },
      refundList: [],
      total: 0,
      filterData: {
        ...enumConverter,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getStatus(status){
       return refundStatus[status];
    },
    async getList() {
      let params = _.cloneDeep(this.queryInfo);
      const { data: res } = await this.$http.get("web_api/transaction/refund_list", {
        params,
      });
      if (res.code !== 200) {
        return this.$message.error("获取交易列表失败！");
      }
      this.refundList = res.data;
      this.total = res.total || this.refundList.length;
    },
    // 监听 limit改变的事件
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.limit = newSize;
      this.getList();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      // console.log(newSize)
      this.queryInfo.page = newSize;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped></style>
