<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据中心</el-breadcrumb-item>
      <el-breadcrumb-item>交易流水</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索 添加 -->
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            clearable
            @clear="getList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </el-col>
        <!-- <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true">添加会员</el-button>
        </el-col> -->
      </el-row>
      <!-- 用户列表区域 -->
      <el-table :data="transactionList" border stripe>
        <el-table-column
          width="230px"
          prop="order_no"
          label="订单编号"
        ></el-table-column>
        <el-table-column
          width="300px"
          prop="out_trade_no"
          label="交易订单号"
        ></el-table-column>
        <el-table-column width="150px" label="交易类型">
          <template slot-scope="scope">
            {{ scope.row.type == "deposit" ? "押金" : "尾款" }}
          </template>
        </el-table-column>
        <el-table-column width="150px" label="订单金额（元）">
          <template slot-scope="scope">
            {{ scope.row.total_fee / 100 }}
          </template>
        </el-table-column>
        <el-table-column width="150px" label="退款金额（元）">
          <template slot-scope="scope">
            {{ scope.row.refund_fee / 100 }}
          </template>
        </el-table-column>
        <el-table-column width="150px" label="付款方式">
          <template slot-scope="scope">
            {{ filterData.provider_valuetotext[scope.row.provider] }}
          </template>
        </el-table-column>
        <el-table-column
          width="150px"
          prop="name"
          label="付款用户"
        ></el-table-column>
        <el-table-column width="150px" label="状态">
          <template slot-scope="scope">
              <el-tag type="danger" v-if="scope.row.status == 10">{{ filterData.status_valuetotext[scope.row.status] }}</el-tag>
              <el-tag type="success" v-else> {{ filterData.status_valuetotext[scope.row.status] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="180px" label="创建时间">
          <template slot-scope="scope">
            {{
              scope.row.create_date ? scope.row.create_date : "" | dataFormat
            }}
          </template>
        </el-table-column>
        <el-table-column width="180px" label="支付时间">
          <template slot-scope="scope">
            {{ scope.row.pay_date ? scope.row.pay_date : "" | dataFormat }}
          </template>
        </el-table-column>
        <el-table-column width="180px" label="取消时间">
          <template slot-scope="scope">
            {{
              scope.row.cancel_date ? scope.row.cancel_date : "" | dataFormat
            }}
          </template>
        </el-table-column>
        <el-table-column
          width="150px"
          prop="description"
          label="备注"
        ></el-table-column>
        <el-table-column label="操作" fixed="right" width="150px">
          <template slot-scope="scope">
            <el-button
              v-if="
                scope.row.status == 1 &&
                scope.row.refund_fee < scope.row.total_fee
              "
              type="primary"
              icon="el-icon-revert"
              size="mini"
              title="退款"
              @click="refund(scope.row, scope.$index)"
              >退款</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[2, 5, 10, 15, 20]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <el-dialog title="退款确认" :visible.sync="refundDialogVisible" width="40%"  @close="refundDialogClose">
      <el-form :model="refundFormData" ref="refundFormRef" label-width="200px">
        <el-form-item label="退款金额(元)">
          <el-input
            v-model="refundFormData.refund_fee"
            :disabled="true"
            placeholder="在此输入退款金额"
          ></el-input>
          <div style="color: #666; margin-top: 5px; font-size: 12px">
            最大可退：<span style="color: red; font-weight: 600">{{
              max_refund_fee
            }}</span>
          </div>
        </el-form-item>
        <el-form-item label="退款原因">
          <el-input
            v-model="refundFormData.refund_desc"
            placeholder="在此输入退款原因"
          ></el-input>
        </el-form-item>
        <el-button
          style="width: 300px; margin: 0 auto; margin-left: 200px"
          type="primary"
          @click="refundFormConfirm"
          >确 定</el-button
        >
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { transactionList } from "@/config/config.js";
import { enumConverter, filterToWhere } from "@/common/validator.js";
import _ from "lodash";
export default {
  data() {
    return {
      // 获取用户列表查询参数对象
      queryInfo: {
        query: "",
        // 当前页数
        page: 1,
        // 每页显示多少数据
        limit: 10,
      },
      transactionList: [],
      total: 0,
      filterData: {
        ...enumConverter
      },
      refundFormData: {
        out_trade_no: "",
        refund_fee: "",
        total_fee: "",
        refund_desc: "",
        order_no:""
      },
      max_refund_fee: "",
      refundDialogVisible: false,
      currentDetail: "",
      rowIndex:"",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      // this.transactionList = transactionList;
      // return;
      let params = _.cloneDeep(this.queryInfo);
      //   params.page--;
      const { data: res } = await this.$http.get(
        "web_api/transaction/transaction_list",
        {
          params,
        }
      );
      if (res.code !== 200) {
        return this.$message.error("获取交易列表失败！");
      }
      this.transactionList = res.data;
      this.total = res.total || this.transactionList.length;
    },
    // 监听 limit改变的事件
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.limit = newSize;
      this.getList();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      // console.log(newSize)
      this.queryInfo.page = newSize;
      this.getList();
    },
    refund(row, index) {
      console.log(row)
      this.currentDetail = row;
      this.rowIndex = index;
      this.refundFormData.total_fee = row.total_fee;
      this.max_refund_fee = (row.total_fee - row.refund_fee) / 100;
      this.refundFormData.refund_fee = this.max_refund_fee;
      this.refundFormData.out_trade_no = row.out_trade_no;
      this.refundFormData.order_no = row.order_no;
      this.refundDialogVisible = true;
    },
    async refundFormConfirm() {
      let params = _.cloneDeep(this.refundFormData);
      params.state = params.state == true ? 1 : 0;
      params.refund_fee = params.refund_fee * 100;
      // params.total_fee = params.total_fee * 100;
      const { data: res } = await this.$http.post("web_api/transaction/refund", params);
      if (res.code !== 200) return this.$message.error(res.msg || "退款失败！");
      this.$message.success("退款成功!");
      this.refundDialogVisible = false;
      this.transactionList[this.rowIndex].status =  10;
      // this.getList();
    },
    refundDialogClose(){
      this.refundFormData.total_fee = null;
      this.refundFormData.refund_fee = null;
      this.refundFormData.out_trade_no = null;
      this.refundFormData.refund_desc = null;
      this.refundFormData.order_no = null;
      this.currentDetail = null;
    }
  },
};
</script>

<style lang="less" scoped></style>
